import TextField from "@mui/material/TextField";
import { useState, useRef, FunctionComponent } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";
import MUIDataTable from "mui-datatables";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const columns = ["Particular", "Value"];

interface InputFileUploadProps {
  handleselectedFile: (event: any) => void;
}

const InputFileUpload: FunctionComponent<InputFileUploadProps> = ({
  handleselectedFile,
}) => {
  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      onChange={(e) => handleselectedFile(e)}
    >
      Upload file
      <VisuallyHiddenInput type="file" />
    </Button>
  );
};

export default function SignInSide() {
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [table, setTable] = useState<[string, string][] | null>(null);
  const keyTextbox = useRef<HTMLInputElement>(null);

  const resetAll = () => {
    setError("");
    setLoading(false);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const apiKey = keyTextbox.current?.value;
    if (!apiKey) {
      setError("Please enter your API key");
      setTimeout(() => resetAll(), 3000);
      return;
    }
    if (!file) {
      setError("Please Select a file to upload");
      setTimeout(() => resetAll(), 3000);
      return;
    }
    setTable(null);

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        `${process.env.REACT_APP_HOST}/upload?auth_token=${apiKey}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const tableData: [string, string][] = [];
      for (var key in response.data["kv-pairs"]) {
        const value: string[] = response.data["kv-pairs"][key];
        tableData.push([key, value.join(" ")]);
      }
      setTable(tableData);
      resetAll();
    } catch (e: any) {
      if (e.response) setError(e.response.data.detail);
      else setError("Internal Error Please contact support");
      setTimeout(() => resetAll(), 3000);
    }
  };

  const handleselectedFile = (event: any) => {
    const file = event.target.files[0];
    setFile(file);
  };

  return (
    <Grid
      container
      component="main"
      direction="row"
      alignItems="center"
      justifyContent="center"
      height={"80vh"}
      width={"80vw"}
      margin={"auto"}
    >
      <Grid
        container
        item
        xs={6}
        component={Paper}
        alignItems="center"
        justifyContent="center"
        elevation={6}
        square
      >
        <Grid item xs={12} marginBottom={"5rem"}>
          {loading && <LinearProgress color="success" />}
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="center"
          xs={12}
        >
          {error && (
            <Typography component="h6" variant="h6" color={"purple"}>
              {error}
            </Typography>
          )}
        </Grid>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <InputFileUpload handleselectedFile={handleselectedFile} />
            </Grid>
            <Grid item xs={6}>
              {file && (
                <Typography component="h5" variant="h5">
                  {file.name}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="API Key here"
              type="password"
              id="password"
              inputRef={keyTextbox}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={(e) => handleSubmit(e)}
            >
              Extract Data
            </Button>
            <Grid container></Grid>
          </Box>
        </Box>
      </Grid>
      <Grid
        container
        item
        xs={6}
        component={Paper}
        alignItems="center"
        justifyContent="center"
      >
        {table && (
          <MUIDataTable
            title={"Document Key-Value Fields"}
            data={table}
            columns={columns}
          />
        )}
      </Grid>
    </Grid>
  );
}
