import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import SignInSide from './SignInSide';
import { green, blueGrey } from '@mui/material/colors';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: green[500],
    },
    secondary: {
      main: blueGrey[500],
    },

  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
    <CssBaseline />
    <SignInSide />
    </div>
    </ThemeProvider>
  );
}

export default App;
